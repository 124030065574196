/* About.css */
.about {
    padding: 10px 0 20px 0;
    background-color: #333;
    width: 100%;
  }
  
  .about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .about-title {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .about-title h2 {
    font-size: 36px;
    margin-bottom: 10px;
    color: white;
  }
  
  .about-title p {
    font-size: 18px;
    color: white;
  }
  
  .team-members {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .team-member {
    flex: 1;
    min-width: 300px;
    background-color: #d0d0d0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    text-align: center;
  }
  
  .team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
  
  .member-avatar {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    border: 5px solid #f0f0f0;
  }
  
  .member-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .member-name {
    font-size: 22px;
    margin-bottom: 5px;
    color: #333;
    margin-top: 5px;;
  }
  
  .member-position {
    font-size: 16px;
    color: #d96969;
    margin-bottom: 15px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .member-description {
    margin-bottom: 20px;
    color: #666;
    line-height: 1.6;
  }
  
  .social-links {
    display: flex;
    gap: 10px;
    margin-top: auto;
  }
  
  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    transition: all 0.3s ease;
  }
  
  .social-link.instagram {
    background-color: #E1306C;
  }
  
  .social-link.tiktok {
    background-color: #000000;
  }
  
  .social-link:hover {
    transform: translateY(-3px);
    opacity: 0.9;
  }
  
  .social-link svg {
    width: 20px;
    height: 20px;
  }
  
  /* Responsive design */
  @media (max-width: 1034px) {
    .team-members {
      justify-content: center;
      padding-right: 5px;
      padding-left: 5px;
      flex-direction: row;
    }
    
    .team-member {
        max-width: 300px;
      flex: 0 0 calc(50% - 30px);
    }
  }
  
  @media (max-width: 768px) {
    .team-member {
        max-width: 400px;
      flex: 0 0 100%;
    }
  }

  @media (max-width: 374px) {
    .team-members {
        max-width: 300px;
        min-width: 280px;
        justify-content: center;
        padding: 0;
    }

    .team-member{
        max-width: 300px;
        min-width: 280px;
        padding-right: 5px;
        padding-left: 5px;
    }
}