.main-card{
    --image-wrapper-hw: 75px;
    color: white;
    background-color: black;
    font-family: 'Playfair Display', serif;
    border-radius: 50px;
    height: 280px;
    width: 450px;
    padding: 10px;
}

.body-card{
    position: relative;
    height: 98%;
    width: 98%;
}

.appos{
    position: absolute;
    height: 75px;
    width: 75px;
    font-size: 75px;
    color: antiquewhite;
    z-index: -1;
}

.testimonial-container {
    position: relative;
    color: white;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.testimonial-container .review{
    position: relative;
    width: 95%;
    max-height: 80%;
    overflow-y: scroll;
    font-family:Helvetica, sans-serif;
    font-size: 1.4rem;
    padding: 10px;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.testimonial-container .review::state(scrollbar){
    display: none;
}

.reviewer-rating{
    height: 100px;
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: space-between;
}

.rating{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size:45px;
    color: gold;
}

.reviewer .image-wrapper{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: var(--image-wrapper-hw);
    width: var(--image-wrapper-hw);
    padding-bottom: 5px;
}


.main-card .image-wrapper img{
    object-fit: cover;
    width: 83%;
    height: 83%;
    border-radius: 50%;
    border: 10px solid #5a5d5d;
}



.reviewer .name{
    position: relative;
    display: block;
    font-size: 1rem;
}

.reviewer .name h3{
    margin-block-start: 0;
    text-transform: uppercase;
}



@media screen and (max-width: 800px) {
    .main-card{
        width: 500px;
    }
    
}
