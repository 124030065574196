@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.navElements {
  position: fixed;
  max-height: 100%;
  width: 100%;
  transition: all 0.5s linear;
  &:hover{
      background-color: black;
  }
}

.scroll {
  background-color: #000000db;
}

.navElements .container {
  margin-top: 3vh;
  max-height: 60%;
  padding-bottom: 10px;
}

.hamburger-icon {
  display: none;
}

.hamburger-wrapper {
  display: none;
}

.menu-items {
  display: flex;
  justify-content: center;
}

.menu-items ul {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  cursor: pointer;

}

.menu-items li {
  float: left;
  padding: 2px 1vw;
  border: 1px;
}


.menu-items ul li a {
  color: whitesmoke;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  &:hover {
    color: peachpuff;
  }
}

.menu-items ul li a.active {
  color: peachpuff;
} 

.nav-items .book-button{
  height: inherit !important;
  width: inherit !important;
  border: none;
  background:none;
  margin:0;
  padding:0;
  cursor: pointer;
  font-size: 20px;
  color: white;
  &:hover{
    color: peachpuff;
  }
}

@media screen and (max-width: 600px) {
  .navbar{
    max-height: 55px;
  }
 
  .navElements .container {
    padding-bottom: 0;
  }


  .hamburger-icon {
    position: absolute;
    display: block;
    top: 25%;
    right: 2%;
    cursor: pointer;
    width: 35px;
    height: 35px;
    font-size: 1.8rem;
  }

  .menu-items {
    position: absolute; 
    right: 0;
    width: 0;
    height: auto; 
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .menu-items.active {
    position: absolute;
    width: 50%;
  }

  .menu-items ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .menu-items ul li {
    border: 1px solid rgb(102, 92, 92);
    height: 2.5rem;
    padding: 0.5em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-align: center;
    vertical-align: middle;
    background-color: #000000b8;
  }

  .menu-items ul a:hover{
    box-shadow: #574c4c;
  } 
  
}

@media (max-width:430px){ 
  .navbar{
    width: 100%;
  }
  .navElements{
    max-width: 100%;
  }
}