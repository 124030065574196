
.App {
  text-align: center;
}
.css-1mjt9t2.MuiBox-root{
  height: 95vh;
  max-height: 97vh !important;
}

@media (prefers-reduced-motion: no-preference) {

}

@media screen and (max-width: 450px) {
  .css-1mjt9t2.MuiBox-root {
    width: 90%;
    max-width: 98%;
  }
}
