.footer{
    height: 450px;
    background-color: black;
    color: white;
    padding: 30px 5px 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    --footer-wrapper-width: 960px;
}

.footer-wrapper {
    height: 85%;
    width: var(--footer-wrapper-width);
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.contact-wrapper{
    position: relative;
    width: 50%;
}

.motto-logo{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
}

.footer-logo{
    height: 45%;
    width: 50%;
}

.motto-logo .business-name{
    font-size: 2rem;
    font-weight: bolder;
}

.footer-motto{
    font-size: 1rem;
    font-weight: bold;
}

.footer-logo img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.footer-services{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
}

.footer-services .title {
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-bottom:10px;
    position: relative;
    top: 4%;
    float: right;
}
.footer-services .items-wrapper{
    position: relative;
    height: 90%;
    width: 75%;
    float: right;
}
.footer-services .items {
    height: 90%;
    list-style: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.footer-services .items li{
    cursor: pointer;
    font-size: 1.25rem;
    &:hover{
        color: rgb(231, 191, 234);
        font-weight: bold;
    }
    /* &::before{
        content: '▶';
        position: absolute;
        left: 0;
    } */
}

.footer .copyrights{
    position: relative;
    display: flex;
    justify-content: space-between;
    color: white;
    width: var(--footer-wrapper-width);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}


.copyrights .credit a{
    text-decoration-color: white;
    color: white;
}

@media screen and (max-width:768px) {
    .footer {
        padding: 30px 5px;
    }
    .footer-wrapper {
        width: 100%;
        justify-content: space-between;
    }
    .footer-services .title{
        right: 0;
        font-size: 1.7rem;
        font-weight: bold;
    }

    .footer-services .items-wrapper {
        float: left;
        width: 95%;
    }
    .footer-services .items-wrapper ul {
        padding-inline-start: 20px;
    }
    
    .footer-logo {
        height: 30%;
    }

    .footer-wrapper .contact-wrapper {
        width: 45%;
    }

    .copyrights{
        width: 100% !important;
    }
}

@media (min-width: 2100px) {
    .footer-services .title{
        text-align: center;
        right: 10%
    }

    .footer-wrapper{
        width: 50%;
    }
}

@media screen and (max-width: 640px) {
    .footer{
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        padding-top: 15px;
    }

    .footer-wrapper{
        height: 98%;
        width: 98%;
        display: block;
        position: relative;
        padding: 5px 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-wrapper .motto-logo {
        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        height: 25%;
    }
    .motto-logo, .footer-services, .footer-services .title {
        position: relative;
        width: 95%;
    }

    .motto-logo .footer-motto {
        padding-top: 10px;
    }

    .footer-services {
        margin-top: 20px;
        display: block;
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-services .items-wrapper{
        width: 100%;
        position: relative;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 20px;
    }

    .footer-services .items {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 0;
    }

    .footer-services .items li{
        font-size: 1.25rem;
        &::before{
            content: none;
            position: absolute;
            left: 0;
        }
    }    
    
    .footer-services .items-wrapper .items li{
        text-decoration: underline;
        text-decoration-color: white;
    }

    
    .motto-logo .business-name {
        color: peachpuff;
    }

    .footer-services .title{
        color: peachpuff;
        margin-left: auto;
    }

    .contact-wrapper{
        padding-top: 10px;
        text-align: center;
        width: 100% !important;
        height: 40%;
    }

    .copyrights{
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center;
        padding: 10px 0;
        width: 98%;
        background-color: black;
        color: white;
    }

    .copyright, .credit{
        padding: 0 5px;
    }

}
@media (max-width:430px){
    .footer-wrapper {
        max-width: 95%;
    }
}

@media (max-width:330px){
    .footer-services .items {
        max-width: 97% !important;
    }
}