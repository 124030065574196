.header_box{
    display: flex;
    justify-content: space-between; 
    color: white;
    font-weight: bold;
    width: 100%;
}

 .socials{
    z-index: 1;
    margin-right: 10px;
    margin-top:10px;
    display: flex;
}

.svg-inline--fa:not(.fa-phone){
    padding: 3px;
}

.direct-call{
    margin-left: 10px;
    margin-top:10px;

}

.direct-call a {
    text-decoration: none;
    color: white;
}

.logo_img{    
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

.logo {
    width: 50px;
    height: 50px;
    margin-top:5px;
}

@media screen and (max-width: 600px) {
    .header_box{
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        max-width: 90%;
        height: 50px; /* height of navElements */
    }
    
    .logo {
        width: 50px;
        height: 50px;
    }

    .logo_img{    
        max-width: 100%;
        max-height: 100%;
    }

    .socials {
        display: none;
    }

    .direct-call{
        position: relative;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        }

}  